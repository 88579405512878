import wrapWithProvider from './wrap-with-provider'
require('@openfonts/montserrat_all')
require('@openfonts/oswald_all')

// Setup Redux
export const wrapRootElement = wrapWithProvider

// Setup PrismJS
//require("prismjs/themes/prism-tomorrow.css")
require("./src/styles/prismjs-theme.css")

// Write a message to the console
console.log(`

  ____          _             ____                       _ _         
 |  _ \\ ___  __| | _____  __ / ___|  ___  ___ _   _ _ __(_) |_ _   _ 
 | |_) / _ \\/ _\` |/ _ \\ \\/ / \\___ \\ / _ \\/ __| | | | '__| | __| | | |
 |  _ <  __/ (_| | (_) >  <   ___) |  __/ (__| |_| | |  | | |_| |_| |
 |_| \\_\\___|\\__,_|\\___/_/\\_\\ |____/ \\___|\\___|\\__,_|_|  |_|\\__|\\__, |
                                                               |___/ 


`)