const modalContactReducerDefaultState = {
  formVisible: false,
  okVisible: false,
  errorVisible: false,
  sourceID: ''
}

const modalContactReducer = (state = modalContactReducerDefaultState, action) => {
  switch (action.type) {
    case 'MODAL_CONTACT_SHOW':
      return { ...state, formVisible: true, sourceID: action.sourceID }
    case 'MODAL_CONTACT_HIDE':
      return { ...state, formVisible: false }
    case 'MODAL_CONTACT_SHOW_OK':
      return { ...state, okVisible: true }
    case 'MODAL_CONTACT_HIDE_OK':
      return { ...state, okVisible: false }
    case 'MODAL_CONTACT_SHOW_ERROR':
      return { ...state, errorVisible: true }
    case 'MODAL_CONTACT_HIDE_ERROR':
      return { ...state, errorVisible: false }
    default:
      return state
  }
}

export default modalContactReducer
